import React, { useState } from "react";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ReportInfo.css";
import ExpandableSection from "../ExpandableSection/ExpandableSection";
const positiveTrainingListMapping = [
  "Motivation To Exercise",
  "Power Response",
  "Recovery Efficiency",
  "Strength Response",
  "Aerobic (V02) Trainability",
  "Aerobic (VO2) Trainability & recovery",
  "Endurance Response",
  "Muscle Mass Predispostion",
  "Lactose Tolerance"
];

const getPathColor = (value, name) => {
  if (positiveTrainingListMapping.includes(name)) {
    if (value === "low") {
      return "#E57373";
    }

    if (value === "medium") {
      return "#FFBB6D";
    }
    if (value === "high") {
      return "#21C26B";
    }
  }
  if (value === "low") {
    return "#21C26B";
  }
  if (value === "medium") {
    return "#FFBB6D";
  }
  if (value === "high") {
    return "#E57373";
  }
};

const getTrailColor = (value, name) => {
  if (positiveTrainingListMapping.includes(name)) {
    if (value === "low") {
      return "#FFCCCC";
    }

    if (value === "medium") {
      return "#F6DEC3";
    }
    if (value === "high") {
      return "#87E1B0";
    }
  }
  if (value === "low") {
    return "#87E1B0";
  }
  if (value === "medium") {
    return "#F6DEC3";
  }
  if (value === "high") {
    return "#FFCCCC";
  }
};

const capitalize = (str = "") => {
  return `${str?.[0]?.toUpperCase()}${str.slice(1)}`;
};

const getProgressBar = (prog) => {
  if (prog > 100) {
    return 100;
  }
  if (prog < 0) {
    return 1;
  }
  return prog;
};

function MyProgressCircle(score, severity, name) {
  const size = 175; // This matches the grey circle size
  const innerCircleSize = size * 0.8; // Making the CircularProgressbar smaller
  const severityStyle = {
    color: "#8E95A9",
    fontSize: "14px",
    fontWeight: "500",
    position: "absolute",
    top: "-20px", // Adjusted to position the text above the circle
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div
      style={{
        width: `${size}px`, // Size of the outer grey circle
        height: `${size}px`, // Size of the outer grey circle
        backgroundColor: "#EEF0FA", // The specified grey color
        borderRadius: "50%", // This makes the div a circle
        display: "flex", // To center the progress circle
        alignItems: "center", // Vertical center
        justifyContent: "center", // Horizontal center
        position: "relative", // For absolute positioning of inner elements
      }}
    >
      <div style={severityStyle}>
        {capitalize(severity)} {/* The severity text */}
      </div>
      <div
        style={{
          width: `${innerCircleSize}px`, // Adjusted size of the progress circle
          height: `${innerCircleSize}px`, // Adjusted size of the progress circle
          position: "absolute", // Position the progress circle absolutely
        }}
      >
        <CircularProgressbar
          value={getProgressBar(score)}
          strokeWidth={10}
          styles={buildStyles({
            pathColor: getPathColor(severity, name),
            textColor: "black",
            trailColor: getTrailColor(severity, name),
            strokeLinecap: "butt",
          })}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: `${innerCircleSize * 0.6}px`, // White circle is smaller than the progress circle
          height: `${innerCircleSize * 0.6}px`, // White circle is smaller than the progress circle
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          color: "black",
          fontWeight: "600",
        }}
      >
        {`${getProgressBar(score)}%`}
      </div>
    </div>
  );
}

const SectionTwo = ({ snps }) => {
  return (
    <div className="section-two">
      {snps?.map((snp, index) => (
        <div key={index} className="snp-container">
          <div className="snp-title">
            <div className="gene-container">
              <span className="label">GENE</span>
              <span className="gene">{snp.title}</span>
            </div>
            <div className="rs-genotype-container">
              <div className="rs-container">
                <span className="label">RS ID</span>
                <span className="rs-id">{snp.rs_id}</span>
              </div>
              <div className="genotype-container">
                <span className="label">GENOTYPE</span>
                <span className="genotype">{snp.genotype}</span>
              </div>
            </div>
          </div>
          <p className="snp-overview">{snp.overview}</p>
        </div>
      ))}
    </div>
  );
};

const ReportInfo = ({
  name,
  score,
  severity,
  description,
  meaning,
  snps,
  output_text,
  identifier,
}) => {
  return (
    <div id={identifier} className="report-info-container">
      <div className="report-info-header">
        <h3>{name}</h3>
      </div>
      <div className="report-info-content">
        {/* Section 1: Title, Description, and Image */}
        <div className="report-info-section section-1">
          <div className="left-column">
            <p>{description}</p>
            <br></br>
            <p>
              <em>{output_text}</em>
            </p>
          </div>
          <div className="right-column">
            {MyProgressCircle(score, severity, name)}
          </div>
        </div>
        {/* Section 2: Meaning Text */}
        <ExpandableSection
          arrowChangesColor={true}
          isButtonOnLeft={true}
          defaultCollapsed={true}
        >
          <div>
            <div className="divider"></div>
            <SectionTwo snps={snps} />
            <div className="divider"></div>
          </div>
          <h3 className="report-info-section-header">
            What does this mean? How can you action this idea?
          </h3>
          <ExpandableSection
            arrowChangesColor={true}
            isButtonOnLeft={true}
            defaultCollapsed={true}
          >
            <div>
              <div className="report-info-section section-3">
                <p>{meaning}</p>
              </div>
            </div>
          </ExpandableSection>
        </ExpandableSection>
      </div>
    </div>
  );
};

export default ReportInfo;
